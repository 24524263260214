<template>
  <v-container>
    <header>
      <h1>Facture</h1>
      <address >
        <p>Five a Day</p>
        <p> 4 Passage de la Râpe <br>contact@fiveaday.fr</p>
        <p>07.54.37.61.10</p>
      </address>
      <span><img alt="" src="https://s.rozee.pk/company_logos/08/73341751851540.png"></span>
    </header>
    <h1>Recipient</h1>
    <address contenteditable="">
      <p>{{ this.currentUser.displayName }}<br>c/o Some Franchisé</p>
    </address>
    <p></p>

    <div> <table class="meta" id="table-one">
      <tr>
        <th><span contenteditable>Facture #</span></th>
        <td><span contenteditable>{{ this.order.number }}</span></td>
      </tr>
      <tr>
        <th><span contenteditable>Date</span></th>
        <td ><input  id="date" type="date"></td>
      </tr>
      <tr>
        <th><span contenteditable> Due Date</span></th>
        <td><input type="date" style="outline: none;"></td>
      </tr>
    </table></div>
    <div>
      <table class="inventory"  id="main-table">
        <tr class="input-group">
          <th> <label for="" id="p-name"> Items</label></th>
          <th> <label for="" id="p-des"> Description</label></th>
          <th><label for="price-input" id="p-price"> Price</label></th>
          <th> <label for="quantity-input" id="p-qty">Quantity</label></th>
          <th><label for="tax-input" id="p-tax">TAX(%)</label></th>

        </tr>

        <tr id="add-row" class="input-group" v-for="product in this.order.products" :key="product.id">

          <td><a class="cut" id="cut-btn" onclick="addRow(false)">-</a>{{product.name}}</td>
          <td>  {{product.productName}}  </td>
          <td>{{product.productPrice}}</td>
          <td>{{product.productQuantity}}</td>
          <td>{{product.productName}}</td>
        </tr>




      </table></div>

    <div><table class="balance">
      <tr>
        <th> TOTAL  :</th>
        <td> <span  id="total-cost" style="font-size: large; font-weight: bold;">{{ this.order.total }}</span></td>
      </tr>

    </table>
      <a  id="reset-row" class="btn-group, add" onclick="addRow(true)">+</a>

      <div id="btn-disapear" class="input-group btn-group">
        <br> <br>
        <button class="success" id="btn-success" onclick="calculateTotalCost()">Calculate</button>
        <div class="spacer"></div>

        <button class="danger" id="btn-reset"  onclick="reset()">Reset</button>
        <div class="spacer"></div>

        <button class="print" id="btn-print" onclick=" displayNone(), printPage();">Print</button></div>

      <p class="cost_texts" id="cost_text"></p>
      <br><br><br><br><br>
      <br><br><br><br><br>
      <aside>
        <h1><span contenteditable></span></h1>
        <div contenteditable>

        </div>
      </aside>
    </div>
  </v-container>
</template>

<script>
import {db} from "../main";
import {mapGetters} from "vuex";

export default {
  name: "Recap",
  data (){
    return{
      note:"",
      order: ""
    }
  },
  computed : {
    ...mapGetters(["currentUser", "cart"]),
  },
  beforeCreate() {
    db.collection("commandes")
        .where("number", "==", this.$route.params.id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.order = doc.data();
            console.log(this.order);
          });
          this.loaded = true;
        });
  },
}
</script>

<style scoped>
html { font: 16px/1 'Open Sans', sans-serif; overflow: auto; padding: 0.5in; }
html { background: #999; cursor: default; }

body { box-sizing: border-box; height: 11in; margin: 0 auto; overflow: hidden; padding: 0.5in; width: 8.5in; }
body { background: #FFF; border-radius: 1px; box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5); }



h1 { font: bold 100% sans-serif; letter-spacing: 0.5em; text-align: center; text-transform: uppercase; }

header { margin: 0 0 3em; }
header:after { clear: both; content: ""; display: table; }

table { font-size: 85%; table-layout: fixed; width: 100%; }
table { border-collapse: separate; border-spacing: 2px; }
th, td { border-width: 1px; padding: 0.5em; position: relative; text-align: left; }
th, td { border-radius: 0.25em; border-style: solid; }
th { background: #EEE; border-color: #BBB; }
td { border-color: #DDD; }



header h1 { background: #000; border-radius: 0.25em; color: #FFF; margin: 0 0 1em; padding: 0.5em 0; }
header address { float: right; font-size: 75%; font-style: normal; line-height: 1.25; margin: 0 1em 1em 0; }
header address p { margin: 0 0 0.25em; }
header span, header img { display: block; float: left; }
header span { margin: 0 0 1em 1em; max-height: 25%; max-width: 60%; position: relative; }
header img { max-height: 50%; max-width: 50%; }
header input { cursor: pointer; -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; height: 100%; left: 0; opacity: 0; position: absolute; top: 0; width: 100%; }


article, article address, table.meta, table.inventory { margin: 0 0 3em; }
article:after { clear: both; content: ""; display: table; }
article h1 { clip: rect(0 0 0 0); position: absolute; }


article address { float: left; font-size: 125%; font-weight: bold; }

/* table meta & balance */

table.meta, table.balance { float: right; width: 36%; }
table.meta:after, table.balance:after { clear: both; content: ""; display: table; display: inline-block;}

/* table meta */

table.meta th { width: 40%; }
table.meta td { width: 60%; }
table.meta td input {border: transparent; font-weight: bold;
}


/* table items */

table.inventory { clear: both; width: 100%;  border-color: black; border-bottom-style: solid;  }
table.inventory th { font-weight: bold; text-align: center; }

table.inventory td:nth-child(1) { width: 26%; }
table.inventory td:nth-child(2) { width: 38%; }
table.inventory td:nth-child(3) { width: 12%; }
table.inventory td:nth-child(4) { width: 12%; }
table.inventory td:nth-child(5) { width: 12%; }
table.inventory td{ margin-top: 8px;}

/* table balance */

table.balance th { width: 50%; border: none; font-weight:bold ; }
table.balance td { text-align: right; border: none; width: 50%;}

/* aside */

aside h1 { border: none; border-width: 0 0 1px; margin: 0 0 1em; }
aside h1 { border-color: #999; border-bottom-style: solid; }
.input-group input{
  /* display: block;
width: 100%;
padding: 0.7rem 1rem;
margin-top: 0.5rem;
font-size: 1rem; */
  font-size: 1rem;
  color: #424242;
  outline: none;
  border: 0px solid #ccc;
  border-radius: 0.2rem;

}
#addRow{
  text-align: left;
}

.success {
  background: #9AF;
}
.danger {
  background: #9AF;
}
.print{
  background:#9AF;
}
.input-group button:hover {
  box-shadow: 0 3px 7px 3px rgba(0, 0, 0, 0.3);
  font-size: 1.3rem;
}
.add, .cut
{
  border-width: 1px;
  display: block;
  font-size: .8rem;
  padding: 0.25em 0.5em;
  float: left;
  text-align: center;
  width: 0.6em;
}
.input-group button {
  display: block;
  width: 80%;
  padding: 1rem;
  text-align: center;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  outline: none;
  border-radius: 0.2rem;
  cursor: pointer;
  box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.1s;
  margin-top: 32px;
}


.btn-group {
  width: 100%;
  display: flex;
}

.add, .cut
{
  background: #9AF;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  background-image: -moz-linear-gradient(#00ADEE 5%, #0078A5 100%);
  background-image: -webkit-linear-gradient(#00ADEE 5%, #0078A5 100%);
  border-radius: 0.5em;
  border-color: #0076A3;
  color: #FFF;
  cursor: pointer;
  font-weight: bold;
  text-shadow: 0 -1px 2px rgba(0,0,0,0.333);
}

.add { margin: -2.5em 0 0; }

.add:hover { background: #00ADEE; }

.cut { opacity: 0; position: absolute; top: 0; left: -1.5em; }
.cut { -webkit-transition: opacity 100ms ease-in; }

tr:hover .cut { opacity: 1; }


.mtop {
  margin-top: 2rem;
}
.spacer {
  width: 2rem;
}
.result p {
  color: #222222;
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.3;
}
.result .cost_texts {
  color: #228b22;
}
@media only screen and (max-width: 700px) {
  .container {
    width: 90%;
  }
}



@page { margin: 0; }

</style>